/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from "react";
import { Logo } from "./NavbarLogo";
import { NavLinksMobile } from "./NavLinksMobile";
import './Navbar.css';



export function NavbarCustom(){
  const [isOpen, setOpen] = useState(false);

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  }
  const isMobile = useMediaQuery('(max-width: 590px)')



  if (isMobile) {
    return(
      <div className="Navbar-container" style={(isOpen)
      ? {paddingTop: 30, paddingBottom: 246, paddingRight:30, paddingLeft: 30, backgroundColor: "var(--color-background-darker-role)"}
      : {paddingLeft: 30, paddingRight: 30, paddingTop: 0, paddingBottom : 0}}
      >
        <div className="left-section-nav">
          {isMobile && <Logo />}
        </div>
        <div className="middle-section-nav" />
        <div className="right-section-nav">
          {isMobile && <NavLinksMobile toggle={setOpen} state={isOpen} />}
        </div>
      </div>
    );
  }
  return (null);
}
