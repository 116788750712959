import React, { Component } from 'react';
import { Button, FormControl, FormGroup, ControlLabel, HelpBlock, Image } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { emailValid } from '../../lib/validation';
import config from '../../constant/config';
import './AddUser.css';

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: '',
      validEmails: true,
      emailExternalResponse: '',
      usernames: '',
      validUsernames: true,
      userOptions: [],
      userUpdated: false,
      users: [], // Store selected users for invitation
    };
    this.renderMenuItemChildren = this.renderMenuItemChildren.bind(this);
  }

  onUserSelect(users) {
    this.setState({ users });
  }

  async onSendInviteClick() {
    if (this.validateEmails()) {
      const emails = this.state.emails.split(',').map(email => email.trim());
      for (const email of emails) {
        try {
          let res;
          if (this.props.hubId) {
            res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/inviteUnregistered`, {
              method: 'POST',
              credentials: 'include',
              headers: { 'content-type': 'application/json' },
              body: JSON.stringify({ email, hubId: this.props.hubId }),
            });

            if (res.status === 200) {
              this.setState({ emailExternalResponse: 'Email invitations sent.' });
            } else if (res.status === 401) {
              throw new Error('You have no privileges to perform this action');
            } else if (res.status === 409) {
              throw new Error('User with this email address already exists. Use "Invite Existing User" above');
            } else {
              throw new Error('Unable to send email invite. Please try again.');
            }
          } else {
            res = await fetch(`${config.apiURL}/user/invite`, {
              method: 'POST',
              credentials: 'include',
              headers: { 'content-type': 'application/json' },
              body: JSON.stringify({ email }),
            });

            if (res.status !== 200) {
              throw new Error('Unable to send email invite.');
            } else {
              this.setState({ emailExternalResponse: 'Email invitations sent.' });
            }
          }
        } catch (err) {
          this.setState({ emailExternalResponse: err.toString() });
        }
      }
    }
  }

  async handleUserInvite() {
    if (this.state.users.length > 0) {
      for (const user of this.state.users) {
        try {
          const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/invite`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ inviteeId: user.id }),
          });
          if (res.status === 200) {
            this.setState({ userUpdated: true, user: null });
          }
        } catch (err) {
          this.setState({ emailExternalResponse: err.toString() });
        }
      }
    }
  }

  validateEmails() {
    const emails = this.state.emails.split(',').map(email => email.trim());
    const allValid = emails.every(email => emailValid(email));
    if (this.state.emails === '' || !allValid) {
      this.setState({ validEmails: false, userUpdated: false });
      return false;
    }
    return true;
  }

  handleUserSearch(query) {
    if (query) {
      fetch(`${config.apiURL}/user/search/${query}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ hubId: this.props.hubId }),
      })
        .then(resp => resp.json())
        .then(json => this.setState({ userOptions: json, userUpdated: false }));
    }
  }

  renderMenuItemChildren(option) {
    return (
      <div className="search-options">
        <Image className="avatar-image" src={`${config.spacesURL}/${option.avatarId}`} />
        <div className="name-container">{`${option.firstName} ${option.surname}`}</div>
      </div>
    );
  }

  render() {
    const validEmails = this.state.emails.split(',').every(email => emailValid(email.trim())) && this.state.emails.length < 255;
    return (
      <div className="add-user-container">
        {!this.state.userUpdated &&
          <div>
            <FormGroup className="form" controlId="formUsernames" validationState={(!this.state.validUsernames) && 'error'}>
              <ControlLabel><strong>Invite existing Actify users to join your hub by entering their username below. If you're inviting more than one user, use a comma between each username.</strong></ControlLabel>
              <AsyncTypeahead
                className="typeahead__container"
                labelKey={option => `${option.firstName} ${option.surname}`}
                filterBy={['firstName', 'surname', 'emailAddress']}
                onSearch={query => this.handleUserSearch(query)}
                searchText="Searching..."
                emptyLabel="No Users"
                minLength={2}
                options={this.state.userOptions}
                onChange={e => this.onUserSelect(e)}
                useCache={false}
                renderMenuItemChildren={this.renderMenuItemChildren}
                multiple
                placeholder="Enter usernames separated by commas or select from suggestions"
              />
              {!this.state.validUsernames && <HelpBlock>Please enter valid usernames</HelpBlock>}
            </FormGroup>
            <div className="button-container">
              <Button className="adduser-button" onClick={() => this.handleUserInvite()}>Invite Existing Users</Button>
            </div>

            <h5>OR</h5>
            <FormGroup className="form" controlId="formEmails" validationState={(!this.state.validEmails || !validEmails) && 'error'}>
              <ControlLabel><strong>Invite those without an Actify account to join your hub by entering their email address below. If you're inviting more than one user, use a comma between each email address.</strong></ControlLabel>
              <FormControl
                className="form-input"
                type="text"
                value={this.state.emails}
                onChange={e => this.setState({ emails: e.target.value, validEmails: true, userUpdated: false, emailExternalResponse: '' })}
                placeholder="Enter emails separated by commas"
              />
              {!this.state.validEmails && <HelpBlock>Please enter valid emails</HelpBlock>}
            </FormGroup>
            <FormControl.Feedback />
            <div className="button-container">
              <Button className="adduser-button" onClick={() => this.onSendInviteClick()}>Send Invites</Button>
            </div>
            {this.state.emailExternalResponse && <div className="external-invite-feedback">{this.state.emailExternalResponse}</div>}
          </div>
        }
        {this.state.userUpdated &&
          <div className="feedback">
            <p className="success-text">Invitations Sent</p>
          </div>}
      </div>
    );
  }
}

export default AddUser;
